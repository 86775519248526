<template>
  <div class="comhead">
      <div class="comhead-nav">
        <div class="comhead-navs" @click="tiaozhuanshouye">科技写作助手</div>
      </div>
    </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();



// 跳转首页
const tiaozhuanshouye=()=>{
  router.push({
    path: '/',
    query: {},
  });
}



</script>

<style scoped>
/* 公共head */
.comhead{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #999;
}
.comhead-nav{
  margin: 0 30px;
}
.comhead-logins{
  width: auto;
  height: 50px;
}
.comhead-logins:after{
  content: '';
  display: table;
  clear: both;
}
.comhead-nav::after{
  content: '';
  display: table;
  clear: both;
}
.comhead-navs{
  height: 50px;
  display: flex;
  align-items: center;
  float: left;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.comhead-navs:hover{
  color: #409eff;
}
.comhead-loginsst-yhm{
  cursor: pointer;
}
.comhead-loginsst-yhm:hover{
  color: #409eff;
}
.comhead-loginsst{
  float: right;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
}
.comhead-loginsst-yhm{
  margin: 0 30px 0 0;
}
</style>
