<template>
  <div class="comhead">
      <div class="comhead-nav">
        <div class="comhead-navs" @click="tiaozhuanshouye('/')">首页</div>
        <div class="comhead-navs" @click="tiaozhuanshouye('/wenzhangxuanti')">文章选题</div>
        <div class="comhead-navs" @click="tiaozhuanshouye('/wenzhangdagang')">文章大纲</div>
        <div class="comhead-navs" @click="tiaozhuanshouye('/xiezuoshili')">写作示例</div>
        <div class="comhead-navs" @click="tiaozhuanshouye('/jishubaogao')">技术报告</div>
      </div>
      <div class="comhead-logins">
        <div class="comhead-loginsst" v-if="islogin==true">
          <div class="comhead-loginsst-yhm" @click="clicktiaozhangezx">{{headusername}}</div>
          <div class="comhead-loginsst-yhm" @click="clicktiaozhangezx">个人中心</div>
          <div class="comhead-loginsst-yhm" @click="clicktiaozhanyiliao">写作记录</div>
          <div class="comhead-loginsst-yhm" @click="loginout">退出</div>
        </div>
        <div class="comhead-loginsst" v-if="islogin==false">
          <div class="comhead-loginsst-yhm" @click="tiaozhuandenglu">登录</div>
          <div class="comhead-loginsst-yhm" @click="tiaozhuanzhuce">注册</div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage,ElMessageBox  } from "element-plus";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

let islogin=ref(false)
let headusername=ref('')


// 更改登录状态
const editlogin = () => {
  if (window.localStorage.getItem("token")) {
    islogin.value = true;
    headusername.value = window.localStorage.getItem("username");
  } else {
    islogin.value = false;
  }
};
editlogin();

// 跳转首页
const tiaozhuanshouye=(urls)=>{
  router.push({
    path: urls,
    query: {},
  });
}
const tiaozhuandenglu=()=>{
  router.push({
    path: '/login',
    query: {},
  });
}
const tiaozhuanzhuce=()=>{
  router.push({
    path: '/registered',
    query: {},
  });
}

// 跳转个人中心
const clicktiaozhangezx=()=>{
  window.localStorage.setItem("navindex", '1');
  router.push({
    path: '/grzx',
    query: {},
  });
}
// 跳转写作记录 默认到医疗
const clicktiaozhanyiliao=()=>{
  window.localStorage.setItem("navindex", '2-4-1');
  router.push({
    path: '/xiezuojilu',
    query: {
      id:21,
    },
  });
}

// 注销
const loginout = () => {
  ElMessageBox.confirm(
    '确定退出系统吗?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      ElMessage({
        message: "已退出系统！",
        duration: 1000,
        onClose: () => {
          // 清除token
          window.localStorage.removeItem("token");
          // 清除个人信息
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("userInfo");
          // 清除url
          window.localStorage.removeItem("loginurl");
          window.localStorage.removeItem("navindex");
          window.localStorage.removeItem("neirongnavindex");
          window.localStorage.removeItem("navid");
          window.localStorage.removeItem("shuruneirong_xiezuo");
          window.localStorage.removeItem("shuruneirong_baogao");
          window.localStorage.removeItem("shuruneirong_dagang");
          window.localStorage.removeItem("shuruneirong_xuanti");
          editlogin();
          router.push({
            path: "/",
            query: {},
          });
        },
      });
    })
    .catch(() => {
      return
    })








  

  // post("/member/logout").then((res) => {
  //   // 清除token
  //   window.localStorage.removeItem("token");
  //   // 清除个人信息
  //   window.localStorage.removeItem("userInfo");
  //   editlogin();
  //   ElMessage({
  //     message: "已退出系统！",
  //     duration: 1000,
  //     onClose: () => {
  //       router.push({
  //         path: "/",
  //         query: {},
  //       });
  //     },
  //   });
  // });
};




</script>

<style scoped>
/* 公共head */
.comhead{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.comhead-nav{
  margin: 0 30px;
}
.comhead-logins{
  width: auto;
  height: 50px;
}
.comhead-logins:after{
  content: '';
  display: table;
  clear: both;
}
.comhead-nav::after{
  content: '';
  display: table;
  clear: both;
}
.comhead-navs{
  height: 50px;
  display: flex;
  align-items: center;
  float: left;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.comhead-navs:hover{
  color: #409eff;
  text-decoration: underline;
}
.comhead-loginsst-yhm{
  cursor: pointer;
}
.comhead-loginsst-yhm:hover{
  color: #409eff;
  text-decoration: underline;
}
.comhead-loginsst{
  float: right;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
}
.comhead-loginsst-yhm{
  margin: 0 30px 0 0;
}
</style>
