<template>
  <div class="main">
    <newhead />
    <div class="ny-con">
      <div class="tjlw">
        <div class="tjlw-title">科技写作助手</div>
        <el-form
          :label-position="'top'"
          label-width="200px"
          style="max-width: 800px"
        >
          <el-form-item label="关键词" class="biaotiyouhua-item">
            <el-input
              v-model="title.bt"
              placeholder="必填，建议至少3个关键词"
            />
          </el-form-item>
          <el-form-item label="附加资料" class="biaotiyouhua-item">
            <el-input
              rows="3"
              type="textarea"
              v-model="title.changwenben"
              placeholder="选填，请输入附加资料，长度5000字以内"
            />
          </el-form-item>


          <div class="tjlw-btns">
            <el-button size="large" type="primary" :icon="Suitcase" @click="wenzhangxuanti">文章选题</el-button>
            <el-button size="large" type="primary" :icon="Calendar" @click="wenzhangdagang">文章大纲</el-button>
            <el-button size="large" type="primary" :icon="SetUp" @click="xiezuoshili">写作示例</el-button>
            <el-button size="large" type="primary" :icon="Tickets" @click="jishubaogao">技术报告</el-button>
          </div>
          <div class="tjlw-tips">
            <div class="tjlw-tipsnr">仅文章选题</div>
            <div class="tjlw-tipsnr">包含文章选题和大纲</div>
            <div class="tjlw-tipsnr">包含文章选题和写作示例</div>
            <div class="tjlw-tipsnr">包含病例个案和技术报告</div>
          </div>
        </el-form>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script setup>
import {
  Suitcase,
  Calendar,
  SetUp,
  Tickets,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
let title=reactive({
  bt:'',
  changwenben:'',
})

const wenzhangxuanti=()=>{
  setguanjianci_xuanti()
  router.push({
    path: "/wenzhangxuanti",
    query: {},
  });
}
const wenzhangdagang=()=>{
  setguanjianci_dagang()
  router.push({
    path: "/wenzhangdagang",
    query: {},
  });
}
const xiezuoshili=()=>{
  setguanjianci_xiezuo()
  router.push({
    path: "/xiezuoshili",
    query: {},
  });
}
const jishubaogao=()=>{
  setguanjianci_baogao()
  router.push({
    path: "/jishubaogao",
    query: {},
  });
}

// 保存关键词和附加资料到本地存储以便后续页面调用
const setguanjianci_xuanti=()=>{
  window.localStorage.setItem('shuruneirong_xuanti',JSON.stringify(title))
}
const setguanjianci_dagang=()=>{
  window.localStorage.setItem('shuruneirong_dagang',JSON.stringify(title))
}
const setguanjianci_xiezuo=()=>{
  window.localStorage.setItem('shuruneirong_xiezuo',JSON.stringify(title))
}
const setguanjianci_baogao=()=>{
  window.localStorage.setItem('shuruneirong_baogao',JSON.stringify(title))
}



</script>

<style scoped>
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fff;
  min-width: 1600px;
}



.ny-con {
  width: 100%;
  height: calc(100% - 100px);
  min-height: 600px;
  background: #fff;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
}
.tjlw {
  width: 800px;
  height: 100%;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0 10px 0;
}
.tjlw-tips{
  font-size: 14px;
  color: #888;
  display: flex;
  justify-content: center;
}
.tjlw-tipsnr{
  width: 200px;
  text-align: center;
}



















/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}











</style>
